import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { siteVars, colors } from "../components/siteVars"
import Loader from "../components/loader"

const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    margin: 0;
  }

  button {
    ${siteVars.buttonReset}
    ${siteVars.buttonGreen}
  }
`

class NotFoundPage extends React.Component {
  state = {
    loading: true,
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
  }
  render() {
    return (
      <Layout>
        <StyledNotFoundPage>
          {this.state.loading ? (
            <Loader dualRing color={colors.darkGrey} />
          ) : (
            <>
              <h1>404</h1>
              <p>Sidan hittades inte</p>
              <Link to="/">
                <button>Tillbaka till hem</button>
              </Link>
            </>
          )}
        </StyledNotFoundPage>
      </Layout>
    )
  }
}

export default NotFoundPage
